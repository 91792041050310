import { AccountId, ORG_PERMISSIONS, OrgInvoice__Manual, PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { generateOrgInvoiceId } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";
import { triggerForInvoiceNotifications } from "../notification/invoice-helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";
import { hasOrgPermission } from "../../compute";

export async function orgInvoice__server__createManualOrgInvoices(p: {
  invoicesBase: Omit<OrgInvoice__Manual, "id" | "invoiceGroupId" | "createdAtMS" | "playerBundleId">;
  playerBundleIds: PlayerBundleId[];
  selfAccountId: AccountId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const batchTasks: BatchTask[] = [];

  const nowMS = Date.now();

  const org = await h.Org.getDoc(p.invoicesBase.orgId);

  if (!org || !hasOrgPermission({ org, accountId: p.selfAccountId, permission: ORG_PERMISSIONS.manageFinances })) {
    throw new Error("User does not have permission to create manual invoices for org: " + p.invoicesBase.orgId);
  }

  const newInvoices: OrgInvoice__Manual[] = [];

  for (let i = 0; i < p.playerBundleIds.length; i++) {
    const id = generateOrgInvoiceId();
    const newInvoice = {
      ...p.invoicesBase,
      playerBundleId: p.playerBundleIds[i],
      createdAtMS: nowMS,
      invoiceGroupId: id,
      id
    };
    newInvoices.push(newInvoice);
    batchTasks.push(
      await h.OrgInvoice.add(
        {
          doc: newInvoice
        },
        { returnBatchTask: true }
      )
    );
  }

  await h._BatchRunner.executeBatch(batchTasks);
  await triggerForInvoiceNotifications({
    orgInvoices: newInvoices,
    type: "new"
  });
}

orgInvoice__server__createManualOrgInvoices.auth = async (req: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
