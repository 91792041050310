import {
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceParentWithChildAndPaymentData,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgPayment,
  OverallInvoiceStatus,
  ParentInvoiceData
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { nmiSDK } from "../../utils/nmiSDK";

const SortKeys = {
  due_date_ms: true,
  created_at_ms: true,
  memo: true,
  player_name: true,
  total_paid: true,
  total_invoiced: true,
  total_balance_remaining: true
};

export async function orgInvoice__server__getParentOrgInvoicesForOrg(p: {
  orgId: OrgId;
  selfAccountId: string;
  offset?: number;
  limit?: number;
  searchTerm?: string;
  status?: OverallInvoiceStatus[];
  dueDateStartMS?: number;
  dueDateEndMS?: number;
  sort?: {
    key: keyof typeof SortKeys;
    dir: "desc" | "asc";
  }[];
}) {
  const { getAppPgPool } = getServerHelpers();

  const isConnected = await nmiSDK.verifyConnectivityForOrgId(p.orgId);
  console.log({ isConnected });

  const baseParam = {
    orgId: { valid: true, val: p.orgId },
    offset: { valid: true, val: p.offset || 0 },
    searchTerm: { valid: !!p.searchTerm, val: `%${p.searchTerm?.replace(/ /g, "").toLowerCase()}%` },
    limit: { valid: true, val: p.limit || 100 },
    dueDateEndMS: { valid: !!p.dueDateEndMS, val: p.dueDateEndMS },
    dueDateStartMS: { valid: !!p.dueDateStartMS, val: p.dueDateStartMS }
  };

  const queryParams = _(baseParam)
    .entries()
    .filter(a => !!a[1].valid)
    .map((a, i) => {
      return [a[0], { sqlParam: `$${i + 1}`, value: a[1].val }];
    })
    .fromPairs()
    .value() as { [k in keyof typeof baseParam]?: { sqlParam: string; value: any } };

  const statusPlaceholders = p.status?.length
    ? p.status
        ?.filter(a => !!OverallInvoiceStatus[a])
        .map((__, i) => `$${Object.keys(queryParams).length + i + 1}`)
        .join(", ")
    : undefined;

  const parameters = [...Object.values(queryParams).map(a => a.value), ...(p.status || [])];

  let sort = p.sort?.filter(a => SortKeys[a.key] && ["asc", "desc"].includes(a.dir)).slice(0, 3);

  if (!sort?.length) {
    sort = [{ key: "created_at_ms", dir: "desc" }];
  }

  const orderBy = sort.map(a => `${a.key} ${a.dir}`).join(", ");

  const whereConds = [
    statusPlaceholders ? `status in (${statusPlaceholders})` : "",
    queryParams.searchTerm
      ? `concat(id, ' ', memo, ' ', player_name) ilike '%' || ${queryParams.searchTerm.sqlParam} || '%'`
      : "",
    p.dueDateEndMS ? `due_date_ms < ${queryParams.dueDateEndMS?.sqlParam}` : "",
    p.dueDateStartMS ? `due_date_ms > ${queryParams.dueDateStartMS?.sqlParam}` : ""
  ].filter(Boolean);

  const query = `select *, COUNT(*) OVER() AS total_count
from (
         select c.item ->> 'dueDateMS'                                          as due_date_ms,
                c.item ->> 'createdAtMS'                                        as created_at_ms,
                c.id                                                            as id,
                c.total_due_cents                                               as total_invoiced,
                c.total_paid_including_credits_cents                            as total_paid,
                c.remaining_due_cents                                           as total_balance_remaining,
                c.item ->> 'memo'                                               as memo,
                c.num_invoices                                                  as num_invoices,
                c.num_paid_invoices                                             as num_paid_invoices,
                c.status                                                        as status,
                c.org_id                                                        as org_id,
                c.item                                                          as item,
                trim(concat(pb.item -> 'virtualAthleteAccount' ->> 'firstName', ' ',
                            pb.item -> 'virtualAthleteAccount' ->> 'lastName')) as player_name
         from (
                  select *
                  from f_parent_org_invoice_status(${queryParams.orgId?.sqlParam}, null) a,
                       mirror_orginvoice b
                  where a.parent_org_invoice_id = b.id
                    and a.org_id = ${queryParams.orgId?.sqlParam}
                    and b.item->>'type' = 'manual'
                    and coalesce(cast(b.item->>'deletedAtMS' as numeric), 0) = 0
              ) c,
              mirror_playerbundle pb
         where c.item ->> 'playerBundleId' = pb.id
     ) d
${whereConds.length ? `where ${whereConds.join(" and ")}` : ""}
order by ${orderBy}
limit ${queryParams.limit?.sqlParam}
offset ${queryParams.offset?.sqlParam}`;

  const r1 = await getAppPgPool().query(query, parameters);

  return { data: r1.rows as ParentInvoiceData[], count: (r1.rows[0]?.total_count || 0) as number };
}

orgInvoice__server__getParentOrgInvoicesForOrg.auth = async (r: express.Request) => {
  // Make sure user has correct permission
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
