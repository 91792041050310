import {
  AccountId,
  OrgCoupon,
  OrgInvoiceId,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgPaymentInvoiceCredit,
  OrgPaymentType,
  PaymentMethodType,
  PlayerBundleId
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { BatchTask } from "@ollie-sports/firebase";
import { filterOrgPaymentInvoices, generateOrgPaymentId, getIndividualOrgInvoiceAmountDetails } from "../../utils";
import shortid from "shortid";
import { orgPayment__client__addOrgPaymentInvoiceCredits } from "../orgPayment/orgPayment__addOrgPaymentInvoiceCredits";

export async function orgInvoice__client__forgiveParentAndChildren(p: {
  parentOrgInvoiceId: OrgInvoiceId;
  note: string;
  appliedByAccountId: AccountId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const nowMS = Date.now();

  const [orgInvoice, childrenInvoicesData] = await Promise.all([
    h.OrgInvoice.getDoc(p.parentOrgInvoiceId),
    h.OrgInvoice.query({ where: [{ parentOrgInvoiceId: ["==", p.parentOrgInvoiceId] }] })
  ]);

  const childrenInvoices = childrenInvoicesData.docs;
  console.log(childrenInvoices.length);

  if (!orgInvoice) {
    throw new Error(`Cannot find org invoice: ${p.parentOrgInvoiceId}`);
  }

  if (orgInvoice.type !== OrgInvoiceTypes.manual) {
    throw new Error(
      `Cannot call orgInvoice__client__forgiceParentAndChildren for an org invoice that is not a manual type: ${p.parentOrgInvoiceId}`
    );
  }

  const allOrgInvoices = [orgInvoice, ...childrenInvoices];

  const allOrgPayments = (
    await h.OrgPayment.multiQuery({
      queries: allOrgInvoices.map(oi => {
        return {
          where: [{ invoiceId: ["==", oi.id] }]
        };
      })
    })
  ).docs;

  await orgPayment__client__addOrgPaymentInvoiceCredits({
    orgPaymentInvoiceCredits: _.compact(
      allOrgInvoices.map(oi => {
        const orgPayments = filterOrgPaymentInvoices(allOrgPayments).filter(op => op.invoiceId === oi.id);
        const individualOrgInvoiceDetails = getIndividualOrgInvoiceAmountDetails({
          orgInvoice: oi,
          orgPayments,
          paymentMethodType: PaymentMethodType.card
        });
        if (individualOrgInvoiceDetails.remainingAmount) {
          return {
            type: OrgPaymentType.invoiceCredit,
            appliedByAccountId: p.appliedByAccountId,
            note: p.note,
            orgId: oi.orgId,
            invoiceGroupId: p.parentOrgInvoiceId,
            status: "succeeded",
            amountCents: individualOrgInvoiceDetails.remainingAmount,
            invoiceId: oi.id,
            playerBundleId: oi.playerBundleId
          };
        }
        return null;
      })
    )
  });
}

// i18n certified - complete
