import { getServerHelpers, getUniversalHelpers } from "../../helpers";

import express from "express";
import _ from "lodash";
import moment from "moment-timezone";
import { isProduction } from "../../utils";
import { OrgInvoice__Manual, PlayerBundleId, PlayerBundle__AccountType } from "@ollie-sports/models";
import { triggerForInvoiceNotifications } from "../notification/invoice-helpers";
import { getInvoicesNeedingAttentionForPlayerBundleIds } from "./helpers";
import { validateSelfAccountId, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

export async function orgInvoice__server__getOrgInvoiceIdsNeedingAttentionForPlayerBundleIds(p: {
  selfAccountId: string;
  playerBundleIds: PlayerBundleId[];
}) {
  // SERVER_ONLY_TOGGLE

  const { getAppPgPool } = getServerHelpers();
  const allowedPlayerBundleIds = await getAppPgPool()
    .query(`SELECT id FROM mirror_playerBundle WHERE item->'managingAccounts' ? '${p.selfAccountId}'`)
    .then(a => a.rows.map(b => b.id));

  if (!p.playerBundleIds.every(id => allowedPlayerBundleIds.includes(id))) {
    throw new Error(
      `User ${p.selfAccountId} does not have permission to access specified player bundles! ${p.playerBundleIds.toString()}`
    );
  }

  return getInvoicesNeedingAttentionForPlayerBundleIds(p);
  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__getOrgInvoiceIdsNeedingAttentionForPlayerBundleIds.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
