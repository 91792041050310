import {
  Org,
  OrgInvoiceTypes,
  OrgInvoice__Registration,
  OrgRegistration,
  OrgRegistrationAnswer,
  OrgRegistrationPackage,
  OrgRegistrationQuestion,
  OrgRegistrationQuestionGrouping,
  OrgSeason,
  OrgSeasonId,
  PlayerBundle,
  PlayerBundleId,
  PrettyPlayerBundle
} from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import {
  determineOrderedRegistrationPackageSuggestionsByTeamId,
  determineRequiredRegistrationPackageForPlayerBundle
} from "../../utils/org-registration-packages.utils";
import moment from "moment";
import { fetchPrettyPlayerBundle } from "../../internal-utils/player-bundle-utils";
import { ObjectKeys } from "../../utils";
import {
  generateVirtualOrgInvoiceRegistration,
  getOrgRegistrationQuestionInfoForRegistrationPackage
} from "./org-invoice-helpers";

export async function orgInvoice__server__getInfoForPayingRegistrationInvoiceNoAuth(p: {
  orgId: string;
  orgSeasonId: OrgSeasonId;
  playerBundleId: PlayerBundleId;
}): Promise<{
  prettyPlayerBundle: PrettyPlayerBundle;
  orgInvoice?: Omit<OrgInvoice__Registration, "id" | "invoiceGroupId">;
  orgRegistrationPackage?: OrgRegistrationPackage;
  orgSeason: OrgSeason;
  orgRegistration?: OrgRegistration;
  orgRegistrationQuestionsInfo?: {
    orgRegistrationQuestionGroupings: OrgRegistrationQuestionGrouping[];
    orgRegistrationQuestions: OrgRegistrationQuestion[];
  };
  orgRegistrationAnswers?: OrgRegistrationAnswer[];
} | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const { getAppPgPool } = getServerHelpers();

  const { orgSeasonId, playerBundleId } = p;

  const [playerBundle, orgRegistrationStatusData, orgSeason] = await Promise.all([
    h.PlayerBundle.getDoc(playerBundleId),
    getAppPgPool().query(
      `select * from f_player_bundle_registration_status($3, null) where player_bundle_id = $1 and org_season_id = $2 limit 1`,
      [p.playerBundleId, p.orgSeasonId, p.orgId]
    ),
    h.OrgSeason.getDoc(orgSeasonId)
  ]);
  const orgRegistrationStatusInfo = orgRegistrationStatusData.rows.length
    ? {
        prioritizedRegistrationPackageId: orgRegistrationStatusData.rows[0]["prioritized_registration_package_id"],
        orgRegistration: orgRegistrationStatusData.rows[0]["org_registration"] as OrgRegistration | null | undefined,
        status: orgRegistrationStatusData.rows[0]["status"]
      }
    : undefined;

  if (!playerBundle) {
    return null;
  }

  const [prettyPlayerBundle, orgRegistrationPackage] = await Promise.all([
    fetchPrettyPlayerBundle(playerBundle),
    orgRegistrationStatusInfo?.prioritizedRegistrationPackageId
      ? h.OrgRegistrationPackage.getDoc(orgRegistrationStatusInfo.prioritizedRegistrationPackageId)
      : null
  ]);

  if (!prettyPlayerBundle || !orgSeason) {
    return null;
  }

  if (orgRegistrationStatusInfo?.orgRegistration) {
    const [orgInvoice, orgAnswersData, orgQuestionsInfoData] = await Promise.all([
      h.OrgInvoice.getDoc(orgRegistrationStatusInfo.orgRegistration.orgInvoiceId),
      orgRegistrationStatusInfo.orgRegistration.answerIds
        ? h.OrgRegistrationAnswer.getDocs(Object.keys(orgRegistrationStatusInfo.orgRegistration.answerIds))
        : undefined,
      orgRegistrationPackage ? getOrgRegistrationQuestionInfoForRegistrationPackage(orgRegistrationPackage) : undefined
    ]);

    const orgAnswers = orgAnswersData ? (_.compact(orgAnswersData) as OrgRegistrationAnswer[]) : undefined;
    return {
      prettyPlayerBundle,
      orgSeason,
      orgRegistration: orgRegistrationStatusInfo.orgRegistration,
      orgInvoice: orgInvoice && orgInvoice.type === OrgInvoiceTypes.registration ? orgInvoice : undefined,
      orgRegistrationAnswers: orgAnswers,
      orgRegistrationQuestionsInfo: orgQuestionsInfoData
    };
  }

  let orgInvoice: Omit<OrgInvoice__Registration, "id" | "invoiceGroupId"> | undefined = undefined;
  let orgRegistrationQuestionGroupings: OrgRegistrationQuestionGrouping[] | undefined = undefined;
  let orgRegistrationQuestions: OrgRegistrationQuestion[] | undefined = undefined;

  if (orgRegistrationPackage) {
    const orgInvoiceData = await generateVirtualOrgInvoiceRegistration({
      orgRegistrationPackage,
      orgSeason,
      playerBundleId: p.playerBundleId
    });
    orgInvoice = orgInvoiceData.orgInvoice;
    orgRegistrationQuestions = orgInvoiceData.orgRegistrationQuestions;
    orgRegistrationQuestionGroupings = orgInvoiceData.orgRegistrationQuestionGroupings;
  }

  return {
    prettyPlayerBundle,
    orgInvoice,
    orgSeason,
    orgRegistrationPackage: orgRegistrationPackage ?? undefined,
    orgRegistrationQuestionsInfo: orgRegistrationPackage
      ? {
          orgRegistrationQuestions: orgRegistrationQuestions ?? [],
          orgRegistrationQuestionGroupings: orgRegistrationQuestionGroupings ?? []
        }
      : undefined
  };

  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__getInfoForPayingRegistrationInvoiceNoAuth.auth = async (req: any) => {};

// i18n certified - complete
