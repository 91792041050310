import { PlayerBundleId } from "@ollie-sports/models";
import { getServerHelpers } from "../../helpers";

export async function getInvoicesNeedingAttentionForPlayerBundleIds(p: { playerBundleIds: PlayerBundleId[] }) {
  const { appOllieFirestoreV2: h, getAppPgPool } = getServerHelpers();
  return (
    await getAppPgPool().query(
      `select * from f_parent_org_invoice_status(null, $1)
  where status != 'inProgress' and status != 'paid';`,
      [p.playerBundleIds]
    )
  ).rows.map(r => {
    return {
      orgId: r["org_id"],
      orgInvoiceId: r["parent_org_invoice_id"],
      playerBundleId: r["player_bundle_id"],
      status: r["status"]
    };
  });
}
