import {
  AccountId,
  OrgCouponId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgPaymentType,
  PlayerBundle__AccountType
} from "@ollie-sports/models";
import _ from "lodash";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";

import { triggerForPaymentNotifications } from "../notification/payment-helpers";
import {
  ChargeCardUnexpectedErrorStatusCodes,
  PayInvoiceUnexpectedErrorStatusCodes,
  chargeWithNMIForInvoice,
  logUnexpectedPaymentError
} from "../../utils/payment-helpers";
import { translate } from "@ollie-sports/i18n";
import moment from "moment";

export async function orgInvoice__server__payIndividualOrgInvoiceChild(p: {
  orgInvoice: OrgInvoiceChild;
  todayPaymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
    appliedOrgCouponDetails?: {
      orgCouponId: OrgCouponId;
      discountAmountCents: number;
    };
  };
  nonDefaultPaymentMethodIdToUse?: string;
  manualTriggerMS?: number;
  selfAccountId: AccountId;
  isAutoPayment: boolean;
  locale: string;
  isImmediateRetry?: boolean;
}): Promise<
  | { type: "success" }
  | {
      type: "error";
      prettyErrorReason: string;
      errorCode: PayInvoiceUnexpectedErrorStatusCodes | ChargeCardUnexpectedErrorStatusCodes;
    }
  | { type: "failed"; prettyFailureReason: string }
> {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const nowMS = Date.now();
  const today = moment().format("YYYY-MM-DD");

  try {
    if (!!p.orgInvoice.thisInvoicePaidInFullDateMS) {
      logUnexpectedPaymentError({
        errorCode: "pay-individual-invoice-already-paid",
        olliePipe,
        info: null,
        locationId: "2kjdfkj34kjdfnMDMdlkj"
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage: "This invoice has already been paid",
          serverLocale: p.locale
        }),
        errorCode: "pay-individual-invoice-already-paid"
      };
    }

    const result = await chargeWithNMIForInvoice({
      baseIdempotencyKey: p.orgInvoice.id + today + p.manualTriggerMS + (p.isImmediateRetry ? "-immediate-retry" : ""),
      orgInvoice: { ...p.orgInvoice },
      invoiceGroupId: p.orgInvoice.invoiceGroupId,
      orgId: p.orgInvoice.orgId,
      paymentDetails: {
        baseAmountDueCents: p.todayPaymentDetails.baseAmountDueCents,
        otherFeesAmountDueCents: p.todayPaymentDetails.otherFeesAmountDueCents,
        lateFeeAmountDueCents: p.todayPaymentDetails.lateFeeAmountDueCents
      },
      accountIdToBeCharged: p.selfAccountId,
      locale: p.locale,
      isManualChildInvoiceTrigger: !!p.manualTriggerMS,
      nonDefaultPaymentMethodIdToUse: p.nonDefaultPaymentMethodIdToUse
    });

    if (result.type === "error") {
      return result;
    } else if (result.type === "failed" && result.orgPayment) {
      try {
        await triggerForPaymentNotifications({
          type: "failed",
          failType: "failed-scheduled",
          orgPayment: result.orgPayment,
          orgInvoice: p.orgInvoice
        });
      } catch (e) {
        logUnexpectedPaymentError({
          errorCode: "pay-individual-invoice-failed-invoice-notifications-error",
          olliePipe,
          info: e,
          locationId: "wq3e4kj234kjdkfjerkjKSD"
        });
      }
      return result;
    }

    const newAmountTotalPaid = p.todayPaymentDetails.baseAmountDueCents + p.orgInvoice.derivedTotalAmountPaidCentsBeforeAllFees;

    const [parentInvoice, siblingInvoices] = await Promise.all([
      h.OrgInvoice.getDoc(p.orgInvoice.parentOrgInvoiceId),
      h.OrgInvoice.query({
        where: [{ parentOrgInvoiceId: ["==", p.orgInvoice.parentOrgInvoiceId] }]
      }).then(a => a.docs.filter(b => b.id !== p.orgInvoice.id) as OrgInvoiceChild[])
    ]);

    // Update the Invoice
    try {
      await h._BatchRunner.executeBatch([
        await h.OrgInvoice.update(
          {
            id: p.orgInvoice.id,
            doc: {
              appliedCouponCodeDetails: p.todayPaymentDetails.appliedOrgCouponDetails,
              derivedTotalAmountPaidCentsBeforeAllFees: newAmountTotalPaid,
              thisInvoicePaidInFullDateMS: newAmountTotalPaid >= p.orgInvoice.amountDueCents ? nowMS : undefined
            }
          },
          { returnBatchTask: true }
        ),
        await h.OrgInvoice.update(
          {
            id: p.orgInvoice.parentOrgInvoiceId,
            doc: {
              derivedTotalAmountPaidCentsIncludingChildrenInvoices:
                (parentInvoice?.derivedTotalAmountPaidCentsBeforeAllFees ?? 0) +
                siblingInvoices.reduce((a, b) => a + b.derivedTotalAmountPaidCentsBeforeAllFees, 0) +
                newAmountTotalPaid
            }
          },
          { returnBatchTask: true }
        )
      ]);
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "pay-individual-invoice-batch-tasks-error",
        locationId: "#KJDKJ#$KJ%(DSLKJ",
        olliePipe,
        info: e
      });
    }

    try {
      if (result.orgPayment && result.orgPayment.type === OrgPaymentType.invoiceDefault) {
        triggerForPaymentNotifications({
          type: "successfulChildOrgInvoicePayment",
          orgInvoiceChild: p.orgInvoice,
          orgPayment: result.orgPayment
        });
      }
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "pay-individual-invoice-notification-error",
        olliePipe,
        info: e,
        locationId: "KDJFkjwleasdfas34234"
      });
    }

    return { type: "success" };
  } catch (e) {
    logUnexpectedPaymentError({
      errorCode: "pay-individual-invoice-completely-mysterious-error",
      olliePipe,
      info: e,
      locationId: "KDJFkjwlekrjsdflkj34234"
    });
    return {
      type: "error",
      prettyErrorReason: translate({
        defaultMessage:
          "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
        serverLocale: p.locale
      }),
      errorCode: "pay-individual-invoice-completely-mysterious-error"
    };
  }

  // SERVER_ONLY_TOGGLE
}

orgInvoice__server__payIndividualOrgInvoiceChild.auth = async (req: any) => {
  await validateTokenAndEnsureSelfAccountIdMatches(req);
};

// i18n certified - complete
