import {
  OrgCoupon,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  PlayerBundleId
} from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";
import { BatchTask } from "@ollie-sports/firebase";

export async function orgInvoice__client__updateInstallmentAmounInvoiced(p: {
  orgInvoiceId: OrgInvoiceId;
  newAmountCents: number;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const orgInvoiceChildToUpdate = await h.OrgInvoice.getDoc(p.orgInvoiceId);

  if (
    !orgInvoiceChildToUpdate ||
    !(
      orgInvoiceChildToUpdate.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
      orgInvoiceChildToUpdate.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
    )
  ) {
    return { status: "error" };
  }

  const [parentInvoice, siblingInvoices] = await Promise.all([
    h.OrgInvoice.getDoc(orgInvoiceChildToUpdate.parentOrgInvoiceId),
    h.OrgInvoice.query({
      where: [
        { parentOrgInvoiceId: ["==", orgInvoiceChildToUpdate.parentOrgInvoiceId] },
        { orgId: ["==", orgInvoiceChildToUpdate.orgId] }
      ]
    }).then(a => a.docs.filter(b => b.id !== orgInvoiceChildToUpdate.id) as OrgInvoiceChild[])
  ]);
  if (!parentInvoice) {
    return { status: "error" };
  }

  const nowMS = Date.now();

  console.log("-------------");
  console.log({
    amountDueCents: p.newAmountCents,
    thisInvoicePaidInFullDateMS:
      orgInvoiceChildToUpdate.derivedTotalAmountPaidCentsBeforeAllFees >= p.newAmountCents ? nowMS : undefined
  });
  console.log({
    derivedTotalAmountPaidCentsIncludingChildrenInvoices:
      (parentInvoice?.derivedTotalAmountPaidCentsBeforeAllFees ?? 0) +
      siblingInvoices.reduce((a, b) => a + b.derivedTotalAmountPaidCentsBeforeAllFees, 0) +
      p.newAmountCents
  });

  // Update the Invoice
  try {
    await h._BatchRunner.executeBatch([
      await h.OrgInvoice.update(
        {
          id: orgInvoiceChildToUpdate.id,
          doc: {
            amountDueCents: p.newAmountCents,
            thisInvoicePaidInFullDateMS:
              orgInvoiceChildToUpdate.derivedTotalAmountPaidCentsBeforeAllFees >= p.newAmountCents ? nowMS : undefined
          }
        },
        { returnBatchTask: true }
      ),
      await h.OrgInvoice.update(
        {
          id: parentInvoice.id,
          doc: {
            derivedTotalAmountDueCentsIncludingChildrenInvoices:
              (parentInvoice?.amountDueCents ?? 0) + siblingInvoices.reduce((a, b) => a + b.amountDueCents, 0) + p.newAmountCents
          }
        },
        { returnBatchTask: true }
      )
    ]);
  } catch (e) {}

  try {
    //   triggerForPaymentNotifications({
    //     type: "successfulChildOrgInvoicePayment",
    //     orgInvoiceChild: p.orgInvoice,
    //     orgPayment: result.orgPayment
    //   });
  } catch (e) {}
  return { status: "success" };
}

// i18n certified - complete
